import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import { FunctionComponent } from "react"
import Typography from "@material-ui/core/Typography"
import { useStaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Button from "@material-ui/core/Button"

const useStyles = makeStyles(theme => ({
  container: {
    // backgroundColor: "#aaa"
  },
  header: {
    color: "#0f4c81",
    fontSize: "1.75rem",
    textAlign: "center",
    fontWeight: 600,
    marginBottom: "20px",
  },
  featureContainer: {
    marginBottom: "4em",
  },
  featureImage: {
    [theme.breakpoints.only("xs")]: {
      margin: "20px",
    },
    [theme.breakpoints.only("sm")]: {
      marginLeft: "75px",
      marginRight: "75px",
    },
    [theme.breakpoints.up("md")]: {
      marginLeft: "30px",
      marginRight: "30px",
      marginBottom: "10px",
    },
  },
  feature1: {
    fontSize: "1.4rem",
  },
  feature2: {
    fontSize: "1.4rem",
  },
  feature3: {
    fontSize: "1.4rem",
  },
  button: {
    verticalAlign: "middle",

    // margin: "0 auto 30px auto"
  },
}))

type Props = {
  source?: string
}

export const LandingPageFeatures: FunctionComponent<Props> = props => {
  const classes = useStyles()
  const data = useStaticQuery(graphql`
    query LandingPageFeaturesQuery {
      featureImage1: file(
        relativePath: { eq: "features/KeyFeatures_Fast.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 800, layout: CONSTRAINED, placeholder: BLURRED)
        }
      }
      featureImage2: file(
        relativePath: { eq: "features/KeyFeaturesGlossary.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 800, layout: CONSTRAINED, placeholder: BLURRED)
        }
      }
      featureImage3: file(
        relativePath: { eq: "features/KeyFeatures_FastRocket.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 800, layout: CONSTRAINED, placeholder: BLURRED)
        }
      }
    }
  `)

  return (
    <div className={classes.container}>
      <Typography variant="h2" className={classes.header}>
        Translation Benefits
      </Typography>
      <Container maxWidth="lg">
        <Grid
          container
          alignItems="center"
          justify="center"
          className={classes.featureContainer}
        >
          <Grid item xs={4} md={4}>
            <Typography variant="body1" className={classes.feature1}>
              Immediate translation results
            </Typography>
          </Grid>
          <Grid item xs={6} md={3}>
            <GatsbyImage
              image={data.featureImage1.childImageSharp.gatsbyImageData}
              alt="no-setup illustration"
              className={classes.featureImage}
            />
          </Grid>
        </Grid>
        <Grid
          container
          alignItems="center"
          justify="center"
          className={classes.featureContainer}
        >
          <Grid item xs={6} md={3}>
            <GatsbyImage
              image={data.featureImage2.childImageSharp.gatsbyImageData}
              alt="customized translations with glossary illustration"
              className={classes.featureImage}
            />
          </Grid>
          <Grid item xs={6} md={4} lg={3}>
            <Typography variant="body1" className={classes.feature2}>
              Use glossary to customize translations
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          alignItems="center"
          justify="center"
          className={classes.featureContainer}
        >
          <Grid item xs={6} md={4} lg={3}>
            <Typography variant="body1" className={classes.feature3}>
              Works out of the box with many i18n libraries
            </Typography>
          </Grid>
          <Grid item xs={6} md={3}>
            <GatsbyImage
              image={data.featureImage3.childImageSharp.gatsbyImageData}
              alt="works with i18n libraries illustration"
              className={classes.featureImage}
            />
          </Grid>
        </Grid>

        <Grid
          container
          alignItems="center"
          justify="center"
          className={classes.featureContainer}
        >
          <Button
            component={Link}
            to="/signup"
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => {
              // @ts-ignore
              props.source && window.plausible
                ? window.plausible(props.source)
                : {}
            }}
          >
            Get Started
          </Button>
        </Grid>
      </Container>
    </div>
  )
}
